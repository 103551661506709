import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  processPaymentFailure,
  ProcessPaymentRequest,
  ProcessPaymentResponse,
  processPaymentStart,
  processPaymentSuccess,
  TransactionStates,
} from '../../slices';
import { PaymentStateChecker } from '../../helpers';

export const paymentProcessingEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(processPaymentStart.type),
    switchMap((action: { payload: ProcessPaymentRequest }) =>
      Api.processPayment(action.payload).pipe(
        map((data: ProcessPaymentResponse) => {
          //@TODO: replace this complex transaction check for all available stateFlags from action to reducer
          const currentTransactionState = (data.transactionState ||
            data.status) as TransactionStates;

          if (
            PaymentStateChecker.isSuccess(
              action.payload.paymentType,
              currentTransactionState,
            ) ||
            PaymentStateChecker.isPending(
              action.payload.paymentType,
              currentTransactionState,
            ) ||
            'resultAction' in data
          )
            return processPaymentSuccess(data);

          return processPaymentFailure(data);
        }),
        catchError((err) => of(processPaymentFailure(err))),
      ),
    ),
  );
