import { PaymentStateChecker } from '../helpers';
import { FinalScreenTypes } from '../interfaces';
import { CashierCommunicationTasks, executeTask } from '../models';
import {
  changeConfirmationFieldValue,
  DynamicScreens,
  getCertainPaymentMethodSuccess,
  getPaymentInfoSuccess,
  PaymentMethodInput,
  processPaymentFailure,
  setActiveScreen,
  setConfirmationFormData,
  setPaymentInputs,
  setTermsAgreementCheckbox,
} from '../slices';

export const platformProcessingInitiatorMiddleware =
  (store) => (next) => (action) => {
    const result = next(action);

    const { cashier_config, transaction_processing, payment_registration } =
      store.getState();
    const { activeType } = cashier_config;

    if (action.type === getCertainPaymentMethodSuccess.toString()) {
      executeTask(CashierCommunicationTasks.onPaymentFormReady);
    }

    if (
      action.type === setPaymentInputs.toString() ||
      action.type === setTermsAgreementCheckbox.toString()
    ) {
      const {
        accounts: { activeAccountOption },
        customerInformation: { termsAgreement },
        payment_form: { paymentInputs },
      } = store.getState();

      const fieldsInformation = Object.entries(paymentInputs).reduce(
        (acc, [name, field]: [name: string, field: PaymentMethodInput]) => {
          if (name === 'accountId') {
            acc.push({
              name,
              value: activeAccountOption.value,
              isValid: Boolean(activeAccountOption.value),
            });
          } else {
            // todo check amount field on favorite amounts
            acc.push({
              name,
              value: field.value || null,
              isValid: field.isValid || false,
            });
          }

          return acc;
        },
        [],
      );

      const agreementCheckbox = termsAgreement.isCheckboxVisible
        ? [
            {
              name: 'agreementCheckbox',
              value: termsAgreement.isChecked,
              isValid: termsAgreement.isChecked,
            },
          ]
        : [];

      executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, [
        ...fieldsInformation,
        ...agreementCheckbox,
      ]);
    }

    if (
      action.type === changeConfirmationFieldValue.toString() ||
      action.type === setConfirmationFormData.toString()
    ) {
      const {
        confirmationForm: { confirmationData },
      } = store.getState();

      executeTask(
        CashierCommunicationTasks.onPaymentAttributesChanged,
        Object.values(confirmationData),
      );
    }

    if (action.type === getPaymentInfoSuccess.toString()) {
      const resultAction =
        transaction_processing.processingResponse.resultAction ||
        payment_registration.registrationResponse.resultAction;
      if (
        resultAction.type === FinalScreenTypes.Display ||
        PaymentStateChecker.isSuccess(activeType, action.payload.state)
      ) {
        executeTask(CashierCommunicationTasks.onPaymentProcessed, {
          status: 200,
        });
        executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, []);
      }

      if (PaymentStateChecker.isFailed(activeType, action.payload.state)) {
        executeTask(CashierCommunicationTasks.onPaymentProcessed, {
          status: 500,
        });
        executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, []);
      }
    }

    if (action.type === processPaymentFailure.toString()) {
      executeTask(CashierCommunicationTasks.onPaymentProcessed, {
        status: 500,
      });
      executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, []);
    }

    if (
      action.type === setActiveScreen.toString() &&
      action.payload === DynamicScreens.confirmationForm
    ) {
      executeTask(CashierCommunicationTasks.onPaymentDataConfirmationInitiated);
    }

    return result;
  };
