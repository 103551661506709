import {
  choosePaymentMethod,
  DynamicScreens,
  getPaymentInfoSuccess,
  makeConfirmRequestFailure,
  makeConfirmRequestSuccess,
  paymentCurrentStateStart,
  paymentCurrentStateSuccess,
  PaymentTransaction,
  processPaymentFailure,
  ProcessPaymentResponse,
  processPaymentSuccess,
  resetPaymentMethod,
  resetStore,
  setActiveScreen,
  setAmount,
  TransactionStates,
} from '../slices';
import { PaymentStateChecker } from '../helpers';

/* eslint-disable import/prefer-default-export */
export const commonMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { cashier_config } = store.getState();
  const { activeType } = cashier_config;

  if (action.type === getPaymentInfoSuccess.toString()) {
    const { payload }: { payload: PaymentTransaction } = action;

    if (
      payload.state === TransactionStates.SUCCESSFUL ||
      payload.state === TransactionStates.PROCESSING
    ) {
      store.dispatch(
        paymentCurrentStateStart({ transactionId: payload.transactionId }),
      );
    }

    store.dispatch(setActiveScreen(DynamicScreens.finalScreen));
  }

  if (action.type === paymentCurrentStateSuccess.toString()) {
    const { payload }: { payload: PaymentTransaction } = action;
    const {
      customerInformation: { activeScreen },
    } = store.getState();

    if (activeScreen !== DynamicScreens.paymentForm) {
      if (payload.state === TransactionStates.PROCESSING) {
        store.dispatch(
          paymentCurrentStateStart({ transactionId: payload.transactionId }),
        );
      }

      if (PaymentStateChecker.isFinalized(activeType, payload.state)) {
        store.dispatch(setActiveScreen(DynamicScreens.finalScreen));
      }
    }
  }

  if (action.type === choosePaymentMethod.toString()) {
    const {
      transaction_processing: { processingResult },
    } = store.getState();

    if (processingResult?.errors) {
      store.dispatch(resetStore());
    }

    document.documentElement.scrollTop = 0;
  }

  /* resetAmount after any changes in PaymentMethod */
  if (
    [choosePaymentMethod.toString(), resetPaymentMethod.toString()].includes(
      action.type,
    )
  ) {
    store.dispatch(setActiveScreen(DynamicScreens.paymentForm));
    store.dispatch(setAmount(null));
  }

  if (action.type === makeConfirmRequestSuccess.toString()) {
    const { payload }: { payload: ProcessPaymentResponse } = action;

    store.dispatch(processPaymentSuccess(payload));
  }

  if (action.type === makeConfirmRequestFailure.toString()) {
    const { payload } = action;

    store.dispatch(processPaymentFailure(payload));
  }

  return result;
};
