import { createSelector } from '@reduxjs/toolkit';

import { CASHIER_CONFIG_FEATURE_KEY } from './';

export const selectCashierConfig = (rootState) =>
  rootState[CASHIER_CONFIG_FEATURE_KEY];

export const selectWindowFlow = createSelector(
  selectCashierConfig,
  (s) => s.windowExist,
);

export const selectActiveType = createSelector(
  selectCashierConfig,
  (s) => s.activeType,
);
