import { AMOUNT_FIELD_NAME, FIAT_AMOUNT } from '../../constants';
import { FieldType } from '../../models';
import { InputMetaData } from '../../slices';
import {
  validateByCharactersType,
  validateByLimits,
  validateByScale,
} from './amountSpecificValidator';

interface ValidatorOptions {
  [FieldType.Email](input: HTMLInputElement): boolean;
  [FieldType.Number](input: HTMLInputElement): boolean;
  [FieldType.CreditCardNumber](input: HTMLInputElement): boolean;
  [FieldType.CreditCardVisa](input: HTMLInputElement): boolean;
  [FieldType.CreditCardMastercard](input: HTMLInputElement): boolean;
  [FieldType.Cvv](input: HTMLInputElement): boolean;
  [FieldType.DateTime](input: HTMLInputElement): boolean;
  [FieldType.Text](input: HTMLInputElement, params: InputMetaData): boolean;
  [FieldType.PayzenPayTmAccountNumber](input: HTMLInputElement): boolean;
  [FieldType.PayzenImpsAccountNumber](input: HTMLInputElement): boolean;
  [FieldType.Integer](input: HTMLInputElement): boolean;
  [FieldType.IdentificationNumber](input: HTMLInputElement): boolean;
  [FieldType.Input](input: HTMLInputElement, params: InputMetaData): boolean;
}

export const CommonValidator: ValidatorOptions = {
  [FieldType.Integer](number: HTMLInputElement): boolean {
    const { min, max, value } = number;

    if (!/^\d+$/.test(value)) return false;

    if (!min && !max) return true;

    if (min && !max) {
      return Number(value) >= Number(min);
    }

    if (max && !min) {
      return Number(value) <= Number(max);
    }

    return Number(value) >= Number(min) && Number(value) <= Number(max);
  },
  [FieldType.IdentificationNumber](number: HTMLInputElement): boolean {
    const { value } = number;

    return /^[0-9-]{8,11}$/.test(value);
  },
  [FieldType.PayzenPayTmAccountNumber](number: HTMLInputElement): boolean {
    const { value } = number;

    return /^\d+$/.test(value) && value.length === 10;
  },
  [FieldType.PayzenImpsAccountNumber](number: HTMLInputElement): boolean {
    const { value } = number;

    return /^\d+$/.test(value) && value.length >= 10 && value.length <= 16;
  },
  [FieldType.Email](email: HTMLInputElement): boolean {
    return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
      email.value,
    );
  },
  [FieldType.Number](number: HTMLInputElement): boolean {
    const { min, max, value } = number;

    if (!min && !max) return true;

    if (min && !max) {
      return Number(value) >= Number(min);
    }

    if (max && !min) {
      return Number(value) <= Number(max);
    }

    return Number(value) >= Number(min) && Number(value) <= Number(max);
  },
  [FieldType.CreditCardNumber](number: HTMLInputElement): boolean {
    const { value } = number;
    return value.length <= 23 && value.length >= 19;
  },
  [FieldType.CreditCardVisa](number: HTMLInputElement): boolean {
    const { value } = number;
    return /^4\d{3}([ -]?)\d{4}\1\d{4}\1\d{4}$/.test(value);
  },
  [FieldType.CreditCardMastercard](number: HTMLInputElement): boolean {
    const { value } = number;
    return /^5[1-5]\d{2}([ -]?)\d{4}\1\d{4}\1\d{4}$/.test(value);
  },
  [FieldType.Cvv](number: HTMLInputElement): boolean {
    const { value } = number;
    return /^[0-9]{3,4}$/.test(value);
  },
  [FieldType.DateTime](date: HTMLInputElement): boolean {
    const { value } = date;

    if (!value.includes('_')) {
      const month = value.substring(0, 2);
      const year = value.substring(3, 7);
      const dateString = `${year}-${month}-${new Date().getDate()}`;
      const expiryDate = new Date(dateString.replace(/-/g, '/'));

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      return expiryDate >= currentDate;
    }

    return false;
  },
  [FieldType.Input](input: HTMLInputElement, params: InputMetaData): boolean {
    const { value, name, min, max } = input || {};

    if (name === FIAT_AMOUNT) {
      if (!validateByCharactersType(value)) {
        return false;
      }

      const isValidByLimits = validateByLimits(value, Number(min), Number(max));
      const isValidByScale = validateByScale(value, params);

      return isValidByLimits && isValidByScale;
    }

    return true;
  },
  [FieldType.Text](input: HTMLInputElement, params: InputMetaData): boolean {
    const { value, name, min, max } = input || {};

    if (name === AMOUNT_FIELD_NAME) {
      const { balance } = params || { balance: 0 };
      if (balance && balance < Number(value.replace(',', '.'))) {
        return false;
      }

      if (!validateByCharactersType(value)) {
        return false;
      }

      const isValidByLimits = validateByLimits(value, Number(min), Number(max));
      const isValidByScale = validateByScale(value, params);

      return isValidByLimits && isValidByScale;
    }

    return true;
  },
};
