import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FieldType } from '../../models';

export interface InputMetaData {
  pattern?: string;
  errorMessage?: string;
  preprocessed?: boolean;
  scale?: number;
  options: ValueOption[];
  min?: number;
  max?: number;
  currency?: string; // smells very bad because input validator knows about Domain
  balance?: number; // smells very bad because input validator knows about Domain
}

export interface PaymentMethodInput {
  name: string;
  required: boolean;
  type: FieldType;
  value?: string;
  label?: string;
  placeholder?: string;
  icon?: string;
  encrypted?: boolean;
  mask?: string;
  errorMsg?: string;
  isValid?: boolean;
  isFocused?: boolean;
  params?: InputMetaData;
}

interface ValueOption {
  label: string;
  value: string;
}

export interface PaymentFormState {
  paymentInputs: Record<string, PaymentMethodInput>;
  options: ValueOption[];
}

export const PAYMENT_FORM_FEATURE_KEY = 'payment_form';

const initialPaymentFormState: PaymentFormState = {
  paymentInputs: {},
  options: [],
};

export const paymentFormSlice = createSlice({
  name: PAYMENT_FORM_FEATURE_KEY,
  initialState: initialPaymentFormState,
  reducers: {
    setPaymentInputs: (
      state,
      action: PayloadAction<Record<string, PaymentMethodInput>>,
    ) => {
      state.paymentInputs = action.payload;
    },
    makeDirecta24DropdownRequestStart: (
      state,
      action: PayloadAction<{
        url: string;
        parameters: Record<string, string>;
      }>,
    ) => undefined,
    makeOptionsRequestStart: (state, action: PayloadAction<{ url: string }>) =>
      undefined,
    makeOptionsRequestSuccess: (state, action: PayloadAction<any>) => {
      state.options = action.payload.map(({ payerName }) => ({
        value: payerName,
        label: payerName,
      }));
    },
    makeDirecta24DropdownRequestSuccess: (
      state,
      action: PayloadAction<any>,
    ) => {
      if (!action.payload.code) {
        state.options = action.payload.map(({ code, name }) => ({
          value: code,
          label: name,
        }));
      }
    },
    makeDirecta24DropdownRequestFailure: (state, action: PayloadAction<any>) =>
      undefined,
  },
});

export const paymentFormReducer = paymentFormSlice.reducer;

export const {
  setPaymentInputs,
  makeDirecta24DropdownRequestStart,
  makeDirecta24DropdownRequestSuccess,
  makeDirecta24DropdownRequestFailure,
  makeOptionsRequestStart,
  makeOptionsRequestSuccess,
} = paymentFormSlice.actions;
