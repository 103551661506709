import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TransactionType } from '../interfaces';
import {
  selectPaymentCurrentState,
  selectPaymentMethod,
  selectProcessingResponse,
  selectRegistrationRequired,
  selectRegistrationResponseFailed,
  selectRegistrationResponseSuccess,
  TransactionStates,
} from '../slices';
import { useCashierArgs } from './useCashierArgs';
import { PaymentStateChecker } from '../helpers';

enum finalScreenStates {
  pending = 'pending',
  success = 'success',
  failure = 'failure',
  missing = 'missing',
}

export const usePaymentResult = () => {
  const processingResult = useSelector(selectProcessingResponse);
  const isRegistrationRequired = useSelector(selectRegistrationRequired);
  const isRegistrationSuccessed = useSelector(
    selectRegistrationResponseSuccess,
  );
  const isRegistrationFailed = useSelector(selectRegistrationResponseFailed);
  const paymentCurrentState = useSelector(selectPaymentCurrentState);
  const paymentMethod = useSelector(selectPaymentMethod);

  const { final_screen_description, id } = paymentMethod;

  const { t } = useTranslation();
  const {
    cashierArgs: { activeType },
  } = useCashierArgs();
  const {
    status: externalTransactionState,
    transactionState: internalTransactionState,
    description: processDescription,
    redirectOutput,
    message: processMessage,
    error: processError,
  } = processingResult;

  const currentTransactionState =
    internalTransactionState || externalTransactionState;

  const translatedTransactionType = {
    [TransactionType.Deposit]: t('Deposit'),
    [TransactionType.Withdrawal]: t('Withdrawal'),
  };

  if (isRegistrationRequired) {
    if (isRegistrationSuccessed) {
      return {
        status: finalScreenStates.success,
        content: t('successfulRegistrationChannel.message'),
      };
    }

    if (isRegistrationFailed) {
      return {
        status: finalScreenStates.failure,
        content: t('failureRegistrationChannel.message'),
      };
    }
  }

  if (paymentCurrentState) {
    if (paymentCurrentState === TransactionStates.SUCCESSFUL) {
      return {
        status: finalScreenStates.success,
        content: t('successfulTransactionComplex.message', {
          transactionType: translatedTransactionType[activeType],
        }),
      };
    }

    if (
      paymentCurrentState === TransactionStates.CANCELLED ||
      paymentCurrentState === TransactionStates.FAILED
    ) {
      return {
        status: finalScreenStates.failure,
        content: t('failedTransaction.message'),
      };
    }

    if (paymentCurrentState === TransactionStates.PROCESSING) {
      return {
        status: finalScreenStates.pending,
        content:
          final_screen_description && ReactHtmlParser(final_screen_description),
      };
    }
  }

  if (
    (PaymentStateChecker.isSuccess(activeType, currentTransactionState) ||
      PaymentStateChecker.isPending(activeType, currentTransactionState)) &&
    !!final_screen_description
  ) {
    return {
      status: finalScreenStates.success,
      content: ReactHtmlParser(final_screen_description),
    };
  }

  if (
    (PaymentStateChecker.isSuccess(activeType, currentTransactionState) ||
      PaymentStateChecker.isPending(activeType, currentTransactionState)) &&
    redirectOutput === null &&
    processDescription === null
  ) {
    return {
      status: finalScreenStates.success,
      content: t('successfulTransactionComplex.message', {
        transactionType: translatedTransactionType[activeType],
      }),
    };
  }

  if (processError || processMessage) {
    return {
      status: finalScreenStates.failure,
      content: processError || processMessage,
    };
  }

  if (
    PaymentStateChecker.isSuccess(activeType, currentTransactionState) ||
    PaymentStateChecker.isPending(activeType, currentTransactionState)
  ) {
    return {
      status: finalScreenStates.success,
      content: t('successfulTransactionComplex.message', {
        transactionType: translatedTransactionType[activeType],
      }),
    };
  }

  if (!id) {
    return {
      status: finalScreenStates.missing,
      content: t('finalScreenFailure.missingMethod'),
    };
  }

  return {
    status: finalScreenStates.failure,
    content: t('failedTransaction.message'),
  };
};
