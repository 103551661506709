import { FinalScreenTypes } from '../interfaces';

export const isFinalScreenResolved = (payload): boolean => {
  /* code smells (very complex condition) */
  return (
    !(
      payload.resultAction ||
      payload.redirectOutput ||
      payload.paymentAttributes ||
      payload.response?.data ||
      payload.response?.errors
    ) ||
    (payload.resultAction?.type === FinalScreenTypes.Display &&
      payload?.status !== 400)
  );
};
