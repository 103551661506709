import { TransactionType } from '../interfaces';
import { TransactionStates } from '../slices';

interface IPaymentStateChecker {
  isSuccess: (type: TransactionType, state: string) => boolean;
  isFailed: (type: TransactionType, state: string) => boolean;
  isPending: (type: TransactionType, state: string) => boolean;
  isFinalized: (type: TransactionType, state: string) => boolean;
}

export const PaymentStateChecker: IPaymentStateChecker = {
  isSuccess: (type: TransactionType, state: string): boolean => {
    if (TransactionType.Deposit === type) {
      return (
        TransactionStates.SUCCESSFUL === state ||
        TransactionStates.FORCE_SUCCESSFUL === state
      );
    }

    if (TransactionType.Withdrawal === type) {
      return (
        TransactionStates.PROCESSING === state ||
        TransactionStates.SUCCESSFUL === state ||
        TransactionStates.FORCE_SUCCESSFUL === state
      );
    }

    return false;
  },
  isFailed: (_: TransactionType, state: string): boolean => {
    return (
      TransactionStates.FAILED === state ||
      TransactionStates.CANCELLED === state ||
      TransactionStates.FORCE_FAILED === state
    );
  },
  isPending: (type: TransactionType, state: string): boolean => {
    if (TransactionType.Deposit === type) {
      return TransactionStates.PROCESSING === state;
    }

    return false;
  },
  isFinalized: (_: TransactionType, state: string): boolean => {
    return (
      TransactionStates.SUCCESSFUL === state ||
      TransactionStates.FAILED === state ||
      TransactionStates.CANCELLED === state ||
      TransactionStates.FORCE_SUCCESSFUL === state ||
      TransactionStates.FORCE_FAILED === state
    );
  },
};
